import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useAuditLogList() {
  // Use toast
  const toast = useToast()

  const refAuditLogListTable = ref(null)

  const perPage = ref(5)
  const totalAuditLogs = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const resourceFilter = ref(null)
  const employeeFilter = ref(null)
  const actionFilter = ref(null)
  const dateFilterValue = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refAuditLogListTable.value ? refAuditLogListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAuditLogs.value,
    }
  })

  const refetchData = () => {
    refAuditLogListTable.value.refresh()
  }

  watch([currentPage, perPage, resourceFilter, employeeFilter, actionFilter, dateFilterValue], () => {
    refetchData()
  })

  const fetchAuditLogs = (ctx, callback) => {
    store
      .dispatch('audit-log/fetchAll', {
        q: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value,
        sort_by: sortBy.value,
        sort_desc: isSortDirDesc.value,
        table_name: resourceFilter.value,
        dml_created_by: employeeFilter.value,
        dml_action: actionFilter.value,
        date_range: dateFilterValue.value,
      })
      .then(response => {
        if (response.status === 200) {
          const { audit_logs, total } = response.data.data
          callback(audit_logs)
          totalAuditLogs.value = total
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: 'No Data',
              icon: 'AlertTriangleIcon',
              variant: 'info',
            },
          })
          callback(null)
          totalAuditLogs.value = 0
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error getting audit-log list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchAuditLogs,
    perPage,
    currentPage,
    totalAuditLogs,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refAuditLogListTable,

    refetchData,

    // Filters
    resourceFilter,
    employeeFilter,
    actionFilter,
    dateFilterValue,
  }
}
